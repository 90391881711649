import { defineCustomElement, BaseController, parseHTML, renderNodes } from '@mrhenry/wp--custom-elements-helpers';
import dynamicProperties from '@mrhenry/wp--dynamic-properties';

defineCustomElement( 'mr-infinite-scroll', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.dimensions = dynamicProperties( {
				treshold: () => {
					return Math.round( window.innerHeight * 1.5 );
				},
			} );

			this.elements = this.elements || {};

			this.elements.target = this.el.getElementsByClassName( 'js-mr-infinite-scroll-target' )[0];
			this.elements.trigger = this.el.getElementsByClassName( 'js-mr-infinite-scroll-trigger' )[0];

			return this;
		}

		bind() {
			this.on( 'scroll', () => {
				if ( !this.isBusy && this.elements.trigger ) {
					const {
						top,
					} = this.elements.trigger.getBoundingClientRect();

					if ( top < this.dimensions.treshold ) {
						this.fetch();
					}
				}
			}, window, {
				passive: true,
			} );

			this.on( 'click', ( e ) => {
				e.preventDefault();
				e.stopImmediatePropagation();
			}, this.elements.trigger );

			return this;
		}

		fetch() {
			this.isBusy = true;

			const label = this.elements.trigger.textContent;

			this.elements.trigger.textContent = 'Loading…';

			// Fetch
			fetch( this.elements.trigger.href ).then( ( res ) => {
				return res.text();
			} ).then( ( html ) => {
				const {
					content: parsed,
				} = parseHTML( html );

				const content = parsed.getElementsByClassName( 'js-mr-infinite-scroll-target' )[0];
				const trigger = parsed.getElementsByClassName( 'js-mr-infinite-scroll-trigger' )[0];

				if ( trigger ) {
					this.elements.trigger.href = trigger.href;
					this.elements.trigger.textContent = label;
				} else {
					this.elements.trigger.parentNode.removeChild( this.elements.trigger );
					this.off( 'scroll', window );
				}

				if ( content ) {
					const fragment = document.createDocumentFragment();
					renderNodes( content, fragment );
					this.elements.target.appendChild( fragment );
				}

				this.isBusy = false;
			}, () => {
				this.isBusy = false;
			} );
		}
	},
} );
