import { defineCustomElement, BaseController, attachMediaAttributes, parseHTML, renderNodes } from '@mrhenry/wp--custom-elements-helpers';

class FragmentController extends BaseController {
	resolve() {
		return this.whenMediaMatches();
	}

	init() {
		const render = ( content, container ) => {
			// We need to override the sizes in the pullout
			// so we can save a few KB down the wire
			Array.from( content.children, ( child ) => {
				const image = child.getElementsByClassName( 'collections-overview-item__poster' );

				if ( image ) {
					image.sizes = '384px';
					image.width = 384;
				}

				return child;
			} );

			// Pass the changed content to the renderNodes method
			renderNodes( content, container );
		};

		fetch( this.src ).then( ( res ) => {
			return res.text();
		} ).then( ( html ) => {
			const {
				content,
			} = parseHTML( html, this.selector );
			render( content, this.el );
		} );

		return this;
	}
}

attachMediaAttributes( FragmentController );

defineCustomElement( 'mr-fragment', {
	attributes: [
		'src',
		'selector',
	],
	controller: FragmentController,
} );
