import { defineCustomElement, BaseController, attachMediaAttributes } from '@mrhenry/wp--custom-elements-helpers';
import dynamicProperties from '@mrhenry/wp--dynamic-properties';

class ScrollerController extends BaseController {
	get isEnabled() {
		if ( this.forceDisabled ) {
			return false;
		}

		return this.matchesMedia;
	}

	forceDisable() {
		this.forceDisabled = true;
	}

	removeForceDisable() {
		this.forceDisabled = false;
	}

	get isScrolling() {
		return this._isScrolling;
	}

	set isScrolling( to ) {
		if ( to !== this._isScrolling ) {
			this._isScrolling = !!to;

			if ( this._isScrolling ) {
				this.el.classList.add( 'is-scrolling' );
			} else {
				this.el.classList.remove( 'is-scrolling' );
			}
		}
	}

	resolve() {
		return Promise.all( [
			this.whenMediaMatches(),
			super.resolve(),
		] );
	}

	init() {
		this.isDragging = false;

		this.elements = {
			scrollTarget: this.el.children[0],
		};

		this.dimensions = dynamicProperties( {
			paddingRight: () => {
				const padding = window.getComputedStyle( this.elements.scrollTarget ).paddingRight;

				return parseInt( padding, 10 );
			},
			scrollMax: () => {
				const children = this.elements.scrollTarget.children;
				const last = children[children.length - 1];

				return ( last.offsetLeft + last.offsetWidth + this.dimensions.paddingRight ) - window.innerWidth;
			},
			scrollbarWidth: () => {
				return this.elements.scrollbar.getBoundingClientRect().width;
			},
			scrollbarLeft: () => {
				return this.elements.scrollbar.getBoundingClientRect().left;
			},
			thumbWidth: () => {
				return this.elements.thumb.getBoundingClientRect().width;
			},
		} );

		return this;
	}

	render() {
		const container = document.createElement( 'div' );
		container.className = 'scroller__scrollbar';

		const scrollbar = document.createElement( 'div' );
		scrollbar.className = 'scrollbar';

		const tracker = document.createElement( 'div' );
		tracker.className = 'scrollbar__track';

		const thumb = document.createElement( 'div' );
		thumb.className = 'scrollbar__thumb';

		scrollbar.appendChild( tracker );
		scrollbar.appendChild( thumb );

		container.appendChild( scrollbar );

		this.elements = this.elements || {};
		this.elements.scrollbar = container;
		this.elements.thumb = thumb;

		this.el.appendChild( this.elements.scrollbar );

		const text = Array.from( this.el.getElementsByClassName( 'scroller__block--text' ) );
		const intro = Array.from( this.el.getElementsByClassName( 'scroller__block--intro' ) );

		const scrollable = [
			...text,
			...intro,
		];

		const listenForFocus = ( e ) => {
			if ( e.currentTarget.classList.contains( 'has-focus' ) ) {
				this.removeForceDisable();
				e.currentTarget.classList.remove( 'has-focus' );
			} else {
				this.forceDisable();
				e.currentTarget.classList.add( 'has-focus' );
			}
		};

		const checkScrollables = () => {
			scrollable.forEach( ( element ) => {
				const content = element.getElementsByClassName( 'scroller__block__content' )[0];

				element.classList.remove( 'is-scrollable' );
				element.removeEventListener( 'click', listenForFocus, false );

				if ( !content ) {
					return;
				}

				const offsetHeight = content.offsetHeight;
				const scrollHeight = content.scrollHeight;

				if ( scrollHeight <= offsetHeight ) {
					return;
				}

				element.classList.add( 'is-scrollable' );
				element.addEventListener( 'click', listenForFocus, false );
			} );
		};

		this.on( 'resize', () => {
			return checkScrollables();
		}, window, {
			passive: true,
		} );

		checkScrollables();

		this.animate();

		const content = Array.from( this.el.getElementsByClassName( 'scroller__content' ) );

		if ( content[0] ) {
			if ( 'ontouchstart' in window ) {
				content[0].style.overflow = 'auto';
				content[0].style.overflowX = 'scroll';
				content[0].style.overflowY = 'hidden';
			}
		}

		return this;
	}

	bind() {
		let stopScrollingTimer;

		this.on( 'wheel', ( e ) => {
			if ( this.isEnabled ) {
				e.preventDefault();
				this.elements.scrollTarget.scrollLeft += e.deltaY;
				this.isScrolling = true;

				if ( stopScrollingTimer ) {
					clearTimeout( stopScrollingTimer );
				}

				stopScrollingTimer = setTimeout( () => {
					this.isScrolling = false;
				}, 128 );
			}
		} );

		let isDragging = false;
		let thumbOffset = 0;

		const downHandler = ( e ) => {
			if ( this.isEnabled ) {
				let clientX = e.clientX;
				if ( e.touches && e.touches.length && e.touches[0].clientX ) {
					clientX = e.touches[0].clientX;
				}

				const thumb = this.elements.thumb.getBoundingClientRect();

				thumbOffset = clientX - thumb.left;

				isDragging = true;
				this.isScrolling = true;
				document.body.classList.add( 'is-dragging' );
			}
		};

		const moveHandler = ( e ) => {
			if ( this.isEnabled && isDragging ) {
				let clientX = e.clientX;
				if ( e.touches && e.touches.length && e.touches[0].clientX ) {
					clientX = e.touches[0].clientX;
				}

				let mouseX = ( clientX - thumbOffset - this.dimensions.scrollbarLeft ) / ( this.dimensions.scrollbarWidth - this.dimensions.thumbWidth );
				mouseX = Math.max( 0, Math.min( 1, mouseX ) );

				this.elements.scrollTarget.scrollLeft = Math.round( mouseX * this.dimensions.scrollMax );
			}
		};

		const upHandler = () => {
			if ( this.isEnabled && isDragging ) {
				isDragging = false;
				this.isScrolling = false;
				document.body.classList.remove( 'is-dragging' );
			}
		};

		this.on( 'mousedown', ( e ) => {
			return downHandler( e );
		}, this.elements.thumb, {
			passive: true,
		} );
		this.on( 'touchstart', ( e ) => {
			return downHandler( e );
		}, this.elements.thumb, {
			passive: true,
		} );

		this.on( 'mousemove', ( e ) => {
			return moveHandler( e );
		}, document.body, {
			passive: true,
		} );
		this.on( 'touchmove', ( e ) => {
			return moveHandler( e );
		}, document.body, {
			passive: true,
		} );

		this.on( 'mouseup', ( e ) => {
			return upHandler( e );
		}, document.body, {
			passive: true,
		} );
		this.on( 'touchend', ( e ) => {
			return upHandler( e );
		}, document.body, {
			passive: true,
		} );

		return this;
	}

	animate() {
		const moveThumb = () => {
			if ( this.isEnabled ) {
				const currentOffset = this.elements.scrollTarget.scrollLeft;

				let left = Math.round( ( currentOffset / this.dimensions.scrollMax ) * ( this.dimensions.scrollbarWidth - this.dimensions.thumbWidth ) );

				if ( left > ( this.dimensions.scrollbarWidth - this.dimensions.thumbWidth ) ) {
					left = ( this.dimensions.scrollbarWidth - this.dimensions.thumbWidth );
				}

				this.elements.thumb.style.transform = `translate3d(${left}px, 0, 0)`;
			}

			window.requestAnimationFrame( () => {
				return moveThumb();
			} );
		};

		window.requestAnimationFrame( () => {
			return moveThumb();
		} );
	}
}

attachMediaAttributes( ScrollerController );

defineCustomElement( 'mr-horizontal-scroller', {
	attributes: [],
	controller: ScrollerController,
} );
