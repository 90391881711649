import { BaseController } from '../controllers/base';

interface TimeAgo extends BaseController<HTMLElement> {
	datetime: string
}

class TimeAgoController extends BaseController<HTMLElement> {
	translations = {
		en: {
			ago: 'ago',
			year: {
				singular: 'year',
				plural: 'years',
				pluralize: ( x: number ): string => {
					if ( 1 === x ) {
						return 'year';
					}

					return 'years';
				},
			},
			month: {
				singular: 'month',
				plural: 'months',
				pluralize: ( x: number ): string => {
					if ( 1 === x ) {
						return 'month';
					}

					return 'months';
				},
			},
			week: {
				singular: 'week',
				plural: 'weeks',
				pluralize: ( x: number ): string => {
					if ( 1 === x ) {
						return 'week';
					}

					return 'weeks';
				},
			},
			day: {
				singular: 'day',
				plural: 'days',
				pluralize: ( x: number ): string => {
					if ( 1 === x ) {
						return 'day';
					}

					return 'days';
				},
			},
			hour: {
				singular: 'hour',
				plural: 'hours',
				pluralize: ( x: number ): string => {
					if ( 1 === x ) {
						return 'hour';
					}

					return 'hours';
				},
			},
			minute: {
				singular: 'minute',
				plural: 'minutes',
				pluralize: ( x: number ): string => {
					if ( 1 === x ) {
						return 'minute';
					}

					return 'minutes';
				},
			},
			second: {
				singular: 'second',
				plural: 'seconds',
				pluralize: ( x: number ): string => {
					if ( 1 === x ) {
						return 'second';
					}

					return 'seconds';
				},
			},
		},
	};

	override resolve(): Promise<void> {
		// No need to wait for window.onload
		return Promise.resolve();
	}

	override render(): void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const _this = ( <any> this ) as TimeAgo;

		this.el.textContent = this.makeReadable( _this.datetime ) || this.el.textContent;

		return;
	}

	makeReadable( datetime: string ): string | null {
		const date = new Date( datetime );
		const time = date.getTime();
		const now = new Date();

		if ( Number.isNaN( time ) ) {
			return null;
		}

		const diff = Math.floor( ( now.getTime() - time ) );

		const secondsDiff = Math.round( diff / 1000 );
		const minutesDiff = Math.round( secondsDiff / 60 );
		const hoursDiff = Math.round( minutesDiff / 60 );
		const daysDiff = Math.round( hoursDiff / 24 );
		const weeksDiff = Math.round( daysDiff / 7 );
		const monthsDiff = Math.round( weeksDiff / 4 );
		const yearsDiff = Math.round( monthsDiff / 12 );

		if ( 12 < monthsDiff ) {
			const years = this.translations.en.year.pluralize( yearsDiff );

			return `${yearsDiff} ${years} ${this.translations.en.ago}`;
		} else if ( 7 < weeksDiff ) {
			const months = this.translations.en.month.pluralize( monthsDiff );

			return `${monthsDiff} ${months} ${this.translations.en.ago}`;
		} else if ( 21 < daysDiff ) {
			const weeks = this.translations.en.week.pluralize( weeksDiff );

			return `${weeksDiff} ${weeks} ${this.translations.en.ago}`;
		} else if ( 24 < hoursDiff ) {
			const days = this.translations.en.day.pluralize( daysDiff );

			return `${daysDiff} ${days} ${this.translations.en.ago}`;
		} else if ( 60 < minutesDiff ) {
			const hours = this.translations.en.hour.pluralize( hoursDiff );

			return `${hoursDiff} ${hours} ${this.translations.en.ago}`;
		} else if ( 60 < secondsDiff ) {
			const minutes = this.translations.en.minute.pluralize( minutesDiff );

			return `${minutesDiff} ${minutes} ${this.translations.en.ago}`;
		}

		const seconds = this.translations.en.second.pluralize( secondsDiff );

		return `${secondsDiff} ${seconds} ${this.translations.en.ago}`;
	}
}

export function timeAgo(): {
	controller: new ( el: HTMLElement ) => TimeAgoController
	attributes?: Array<string>
	} {
	return {
		attributes: [
			'datetime',
		],
		controller: TimeAgoController,
	};
}
