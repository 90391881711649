import { defineCustomElement, BaseController, attachMediaAttributes } from '@mrhenry/wp--custom-elements-helpers';

class PulloutController extends BaseController {
	bind() {
		const close = () => {
			if ( !!this.media && this.matchesMedia ) {
				if ( !this.hidden ) {
					this.hidden = true;
				}
			}
		};

		this.on( 'click .js-mr-pullout-toggle', ( e ) => {
			if ( !!this.media && this.matchesMedia ) {
				e.preventDefault();
				e.stopImmediatePropagation();
				this.hidden = !this.hidden;
			}
		}, document.body );

		this.on( 'click .js-mr-pullout-close', () => {
			close();
		}, document.body );

		this.on( 'smoothState:before', () => {
			close();
		}, window );
	}
}

attachMediaAttributes( PulloutController );

defineCustomElement( 'mr-pullout', {
	attributes: [
		{
			attribute: 'hidden',
			type: 'bool',
		},
	],
	controller: PulloutController,
} );
